export * from "ce/hooks/datasourceEditorHooks";
import type { HeaderActionProps } from "ce/hooks/datasourceEditorHooks";
import { useHeaderActions as useHeaderActionsCE } from "ce/hooks/datasourceEditorHooks";
import React from "react";
import type { Datasource } from "entities/Datasource";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHasCreateDatasourceActionPermission } from "ee/utils/BusinessFeatures/permissionPageHelpers";
import NewReusableActionButton from "ee/pages/Editor/PackageEditor/DataSourceEditor/NewReusableActionButton";
import { EditorNames } from "ee/hooks";
import { useSelector } from "react-redux";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "selectors/editorSelectors";
import { getCurrentModuleId } from "ee/selectors/modulesSelector";
import { getCurrentPackageId } from "ee/selectors/packageSelectors";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import NewActionButton from "ee/pages/Editor/WorkflowEditor/DataSourceEditor/NewActionButton";

export const useHeaderActions = (
  editorType: string,
  {
    datasource,
    isPluginAuthorized,
    pluginType,
    showReconnectButton,
  }: HeaderActionProps,
) => {
  const headerActions = useHeaderActionsCE(editorType, {
    datasource,
    isPluginAuthorized,
    pluginType,
    showReconnectButton,
  });
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const canCreateDatasourceActions = getHasCreateDatasourceActionPermission(
    isFeatureEnabled,
    datasource?.userPermissions ?? [],
  );

  if (editorType === EditorNames.APPLICATION) {
    return headerActions;
  } else if (editorType === EditorNames.PACKAGE) {
    const newActionButton = (
      <NewReusableActionButton
        datasource={datasource as Datasource}
        disabled={!canCreateDatasourceActions || !isPluginAuthorized}
        eventFrom="datasource-pane"
        pluginType={pluginType}
      />
    );

    return {
      newActionButton,
      generatePageButton: null,
    };
  } else if (editorType === EditorNames.WORKFLOW) {
    const newActionButton = (
      <NewActionButton
        datasource={datasource as Datasource}
        disabled={!canCreateDatasourceActions || !isPluginAuthorized}
        eventFrom="datasource-pane"
        pluginType={pluginType}
      />
    );

    return {
      newActionButton,
      generatePageButton: null,
    };
  }

  return {};
};

export const useParentEntityInfo = (editorType: string) => {
  const appId = useSelector(getCurrentApplicationId);
  const pageId = useSelector(getCurrentPageId);
  const packageId = useSelector(getCurrentPackageId);
  const moduleId = useSelector(getCurrentModuleId);
  const workflowId = useSelector(getCurrentWorkflowId);

  if (editorType === EditorNames.PACKAGE) {
    return {
      editorId: packageId || "",
      parentEntityId: moduleId || "",
      parentEntityType: ActionParentEntityType.MODULE,
    };
  } else if (editorType === EditorNames.WORKFLOW) {
    return {
      editorId: workflowId || "",
      parentEntityId: workflowId || "",
      parentEntityType: ActionParentEntityType.WORKFLOW,
    };
  } else {
    return {
      editorId: appId || "",
      parentEntityId: pageId || "",
      parentEntityType: ActionParentEntityType.PAGE,
    };
  }
};
